<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :activator="element"
    offset-x
  >
    <v-card color="grey lighten-4" min-width="350px" max-width="500px" flat>
      <v-toolbar :color="event.color" dark>
        <v-toolbar-title v-html="event.name"></v-toolbar-title>
        <v-spacer></v-spacer>
        <delete-item-dialog
          :itemID="event.id"
          :setup="setupDeleteDialog"
          :deleteURL="componentURL"
          :event-name="eventName"
        >
        </delete-item-dialog>
      </v-toolbar>
      <v-card-text>
        <event-form
          ref="eventForm"
          :key="componentKey"
          :form="form.event"
          :itemId="itemId"
          :eventID="event.id"
          :allow-multiple-locations="false"
        >
        </event-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="bg-secondary text-color-white"
          @click="submit()"
          :loading="loading"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
// import moment from "moment";

import { GLOBAL_EVENT_BUS } from "@/services/event_buses/globalEventBus";
// import { DELETE_ITEM_EVENT_BUS } from "@/components/miscellaneous/dialogs/js/DeleteItemEventBus";

import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const eventRepository = RepositoryFactory.get("event");

const EventForm = () => import("../forms/EventForm.vue");
const DeleteItemDialog = () =>
  import("@/components/miscellaneous/dialogs/DeleteItemDialog.vue");

import { convertUtcToLocal, setToUtc } from "@/services/helpers/date";

export default {
  components: {
    EventForm,
    DeleteItemDialog
  },
  props: {
    event: {
      type: Object,
      default: () => {
        return {};
      }
    },
    element: {
      default: null
    }
  },
  data: () => ({
    showMenu: false,
    componentKey: 0,
    form: {},
    itemId: null,
    loading: false,
    eventName: "reloadItems_eventCalendar",
    setupDeleteDialog: {
      button: {
        small: true,
        class: "mx-1",
        color: "error",
        icon: false,
        isIcon: true,
        iconName: "mdi-trash-can-outline"
      },
      card: {
        title: "Event Delete Item"
      }
    },
    componentURL: "api/events"
  }),
  watch: {
    "$attrs.showMenu": {
      handler: function(value) {
        if (value) {
          this.getItem();
        }
        this.showMenu = value;
      },
      immediate: true
    }
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
    },
    submit() {
      let eventForm = this.$refs.eventForm;

      let valid = eventForm.validate();

      if (valid) {
        this.loading = true;

        let form = { ...eventForm.form };

        // form.event_at = this.toUTC(form.startDate, form.startTime);
        // form.event_end_at = this.toUTC(form.endDate, form.endTime);

        form.event_at = setToUtc(`${form.startDate} ${form.startTime}`).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        form.event_end_at = setToUtc(`${form.endDate} ${form.endTime}`).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        eventRepository
          .update(this.event.id, form)
          .then(response => {
            this.MIXINS_SHOW_SNACKBAR("success", "Successfully Updated.");

            let queryOnThisMonth = response.data.responseData.event_at;

            GLOBAL_EVENT_BUS.$emit(this.eventName, queryOnThisMonth);
          })
          .catch(error => {
            console.log({ error });

            let objectError = error.response.data.errors;

            for (const [key] of Object.entries(objectError)) {
              let errorMessage = objectError[key][0];

              this.MIXINS_SHOW_SNACKBAR("error", errorMessage);

              break;
            }
          })
          .finally(() => {
            this.loading = false;

            this.closeMenu();
          });
      }
    },
    getItem() {
      let event = Object.assign({}, this.event);

      this.form = {
        event: {
          name: event.name,
          location: event.location,
          location_id: event.location_id,
          notes: event.notes,
          type: event.type,
          startDate: convertUtcToLocal(event.start).format("Y-MM-DD"),
          startTime: convertUtcToLocal(event.start).format("HH:mm"),
          endDate: convertUtcToLocal(event.end).format("Y-MM-DD"),
          endTime: convertUtcToLocal(event.end).format("HH:mm")

          // startDate: this.formatToDate(event.start),
          // startTime: this.formatToTime(event.start),
          // endDate: this.formatToDate(event.end),
          // endTime: this.formatToTime(event.end),
        }
      };
    }
    /**
     * Comment 7/7/2021 for the reason of refactoring and using the date helper function

      // formatToDate(data) {
      //   let date = moment.utc(moment(data).utc()).local().format("Y-MM-DD");
      //   let date1 = moment(data).utc().local().format("Y-MM-DD");
      //   console.log({ data, date, date1 });
      //   return moment.utc(moment(data).utc()).local().format("Y-MM-DD");
      //   // return moment(data).format("Y-MM-DD");
      // },
      // formatToTime(data) {
      //   let time = moment.utc(moment(data).utc()).local().format("HH:mm");
      //   let time1 = moment(data).utc().local().format("HH:mm");
      //   console.log({ data, time, time1 });
      //   return moment.utc(moment(data).utc()).local().format("HH:mm");
      //   // return moment(data).format("HH:mm");
      // },
      // toUTC(date, time) {
      //   return moment(`${date} ${time}`).utc().format("YYYY-MM-DD HH:mm:ss");
      // },
    */
  }
};
</script>
